import React, { useEffect, useState, useCallback } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Link, useNavigate } from "react-router-dom";
import {
  getLastNWords,
  getStudentQuestions,
  getUTCDate,
  translate,
} from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { selectStudentQuestions } from "./store/studentQuestions/studentQuestions.selector";
import {
  loadStudentQuestionsList,
  setStudentQuestionsList,
} from "./store/studentQuestions/studentQuestions.action";
import Timer from "./Timer";
import { useSocket } from "./Context/SocketContext";

const Dictaphone = () => {
  const [text, setText] = useState("");
  const [lang, setLang] = useState(
    sessionStorage.getItem("teacherLang") || "ar"
  );
  const [teacherDate, setTeacherDate] = useState(
    () => sessionStorage.getItem("teacherDate") || ""
  );
  const [teacherId, setTeacherId] = useState(
    sessionStorage.getItem("teacherId") || ""
  );
  const [topic, setTopic] = useState(() => {
    return sessionStorage.getItem("teacherTopic") || "";
  });
 
  const [roomId, setRoomId] = useState(() => {
    return sessionStorage.getItem("roomId") || "";
  });
  const [listening, setListening] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false); // Track socket connection status
  const dispatch = useDispatch();
  const socket = useSocket();
  const navigate = useNavigate();

  const studentQuestions = useSelector(selectStudentQuestions);

  const commands = [];

  const initalizeSocket = () => {
    socket.on("/teacher/new_student_message", (data) => {
      console.log(data);
      let date = sessionStorage.getItem("teacherDate");
      getStudentQuestions(date,roomId).then((res) => {
        Promise.all(
          res.data.questions.map((item) => {
            console.log(item);
            return translate(item.message, item.language, lang).then((res) => {
              return {
                name: item.studentLectureId.userId.name,
                message: res,
              };
            });
          })
        )
          .then((translatedResults) => {
            dispatch(loadStudentQuestionsList(translatedResults));
          })
          .catch((error) => {
            console.error("Error during translation:", error);
          });
      });
    });
    listenContinuously();
  };

  useEffect(() => {
    if (teacherId == null || teacherId == "") {
      navigate("/teacher-login");
    }
  }, []);

  useEffect(() => {
    if (socket) {
      
      setIsSocketConnected(true);
      socket.on("disconnect", () => {
        console.log("Socket disconnected");
        setIsSocketConnected(false);
      });

      socket.on("connect", () => {
        console.log("Socket reconnected");
        setIsSocketConnected(true);
      });

      initalizeSocket();
    }

    return () => {
      if (socket) {
        const userAgent = navigator.userAgent;

        // OS Information (basic parsing)
        // const osVersion = /Windows/.test(userAgent)
        //   ? "Windows"
        //   : /Mac/.test(userAgent)
        //   ? "MacOS"
        //   : /Android/.test(userAgent)
        //   ? "Android"
        //   : /iPhone|iPad|iPod/.test(userAgent)
        //   ? "iOS"
        //   : "Unknown";

        // Platform information from navigator
        const deviceModel = navigator.platform || "Unknown";

        console.log("Cleaning up socket listeners...");
      }
    };
  }, [socket]);

  const { transcript, interimTranscript, finalTranscript } =
    useSpeechRecognition({ commands });

  useEffect(() => {
    
    if (interimTranscript !== "") {
    
      if (isSocketConnected) {
        socket.emit(
          "/teacher/new_teacher_text",
          JSON.stringify({
            message: interimTranscript,
            userId: teacherId,
            language: lang,
            roomId: sessionStorage.getItem("roomId"),
          })
        );
        console.log(interimTranscript);
        setText(interimTranscript);
        console.log('room: ', sessionStorage.getItem("roomId"));
      } else {
        // console.log("Socket not connected, unable to emit");
      }
    } else {
      // console.log("transcript result not found");
    }
  }, [interimTranscript, listening, lang, socket, isSocketConnected, roomId]);

  const listenContinuously = useCallback(() => {
    setListening(true);
    SpeechRecognition.startListening({
      continuous: true,
      interimResults: true,
      language: lang,
    });

    // Restart listening on recognition end
    SpeechRecognition.onend = () => {
      SpeechRecognition.startListening({
        continuous: true,
        interimResults: true,
        language: lang,
      });
    };

    SpeechRecognition.onerror = (event) => {
      // Restart listening on error
      SpeechRecognition.startListening({
        continuous: true,
        interimResults: true,
        language: lang,
      });
    };
  }, [lang]);

  const handleClearMessage = () => {
    dispatch(loadStudentQuestionsList([]));
    const newDate = getUTCDate();
    sessionStorage.setItem("teacherDate", newDate);
    setTeacherDate(newDate);
  };

  const handleBack = () => {
    const deviceModel = navigator.platform || "Unknown";

    const userAgent = navigator.userAgent;

    // OS Information (basic parsing)
    const osVersion = /Windows/.test(userAgent)
      ? "Windows"
      : /Mac/.test(userAgent)
      ? "MacOS"
      : /Android/.test(userAgent)
      ? "Android"
      : /iPhone|iPad|iPod/.test(userAgent)
      ? "iOS"
      : "Unknown";

    socket.emit(
      "/teacher/user_disconnected",
      JSON.stringify({
        userId: teacherId,
        roomId: sessionStorage.getItem("roomId"),
        topic: topic,
        platform: "web",
        deviceModel: deviceModel,
        osVersion: osVersion,
        appVersion: "1.0.0",
      })
    );

    navigate("/teacher");
  };

  return (
    <div className="discussion_container">
      <div className="dictaphone_header">
        <div className="back_btn" onClick={handleBack}>
          <img className="icon" src="../back.png" alt="Back" />
        </div>
        <h2 className="title">
          {lang === "ar" ? "قائمة الأسألة المطروحة" : "Asked questions list"}
        </h2>
        <button onClick={handleClearMessage} className="chat_button">
          <img className="chat_button_img" src="../clear.svg" alt="Clear" />
        </button>
      </div>
      <div className="student_questions">
        {studentQuestions.length > 0 &&
          studentQuestions.map((item, index) => (
            <div key={index} className="student_message_box">
              <span className="message_count">
                {studentQuestions.length - index}.
              </span>
              <div className="student_content">
                <span className="title_string">
                  {item.name && item.name !== "null" ? item.name : "SeeToHear"}
                </span>
                <span className="message_string">{item.message}</span>
              </div>
            </div>
          ))}
      </div>
      <div className="footer_discussion">
        <div className="button_section" onClick={handleBack}>
          <span className="button_text black">Back</span>
        </div>
        <Timer />
      </div>
    </div>
  );
};

export default Dictaphone;
