import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { sendStudentQuestion } from "./utils";
import Loading from "./Loading";
import { useSocket } from "./Context/SocketContext";

const Message = () => {
  const [translatedLang, setTranslatedLang] = useState(
    () => sessionStorage.getItem("translatedLang") || "ar"
  );

  const [text, setText] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();

  const transLangRef = useRef(null);

  const studentName = sessionStorage.getItem("studentName");
  const studentId = sessionStorage.getItem("studentId");

  const questions = [
    [
      { en: "Can you please repeat that ?", ar: "الرجاء تكرار شرح ذلك" },
      { en: "I didnt understand.", ar: "لم أفهم هذه الجزئية" },
    ],
    [
      { en: "Can you talk slower please ?", ar: "الرجاء التحدث بشكل أبطئ" },
      {
        en: "Could you show another example ?",
        ar: "الرجاء استخدام مثال توضيحي لذلك",
      },
    ],
  ];

  const commands = [
    {
      command: "reset",
      callback: () => resetTranscript(),
    },
    {
      command: "shut up",
      //   callback: () => setMessage("I wasn't talking.")
    },
    {
      command: "Hello",
      //   callback: () => setMessage('Hi there!')
    },
  ];

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("final transcript:", finalTranscript);
      // socket.emit('studentStream', `${text}####${translatedLang}`);
      // setChats([...chats, text]);
      // setText('');
      // resetTranscript();
    }

    if (interimTranscript !== "") {
      console.log("Got interim result:", interimTranscript);
      setText(interimTranscript);
    }

    if (transcript !== "") {
      console.log("Got result:", transcript);

      if (socket) {
        if (interimTranscript !== "") {
        }
      }
    }
  }, [finalTranscript, interimTranscript, translatedLang, transcript, socket]);

  const sendTextMessage = (text) => {
    setLoading(true);
    let data = {
      'message': text,
      'roomId': sessionStorage.getItem("roomId"),
      'userId': studentId,
      'language': translatedLang
    }
    socket.emit(
      "/student/new_student_message",
      JSON.stringify(data) 
    );
    setText("");
    setLoading(false);
    navigate("/lecture", { state: { showMessage: true } });
  };

  const handleSendText = () => {
    if (text.trim()) {
      sendTextMessage(text.trim());
    }
  };

  const sendDefinedText = (text) => {
    sendTextMessage(text);
  };

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    setTranslatedLang(newLang);
    sessionStorage.setItem("translatedLang", newLang);
    transLangRef.current.value = newLang;
  };

  // Memoized function to handle continuous listening
  const listenContinuously = useCallback(() => {
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
      interimResults: true,
      language: translatedLang,
    });
    console.log("Started listening continuously...");
  }, [translatedLang]);

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
    sendTextMessage(text);
  };

  const handleMicrophoneClick = () => {
    isListening ? stopListening() : listenContinuously();
  };

  return (
    <div className="message_container">
      <div className="header">
        <Link className="back_btn" to="/lecture">
          <img className="icon" src="../back.png" alt="Send" />
        </Link>
        <h2 className="title">اسأل سؤالا</h2>
      </div>
      <div className="language_dropdown message_dropdown">
        <label htmlFor="languageDropdown">لغة</label>
        <select
          id="languageDropdown"
          onChange={handleLanguageChange}
          value={translatedLang}
          ref={transLangRef}
          className="select"
        >
          <option value="en">English</option>
          <option value="ar">عربي</option>
          <option value="es">Española</option>
          <option value="sh">中国人</option>
          <option value="fr">Français</option>
          <option value="hi">हिंदी</option>
          <option value="de">Deutsch</option>
          <option value="es-MX">Mexican</option>
          <option value="ja">日本語 (Nihongo)</option>
          <option value="ko">한국어 (Hangugeo)</option>
          <option value="ru">Русский (Russkiy)</option>
          <option value="pt">Português</option>
          <option value="it">Italiano</option>
          <option value="nl">Nederlands</option>
          <option value="el">Ελληνικά (Elliniká)</option>
          <option value="tr">Türkçe</option>
          <option value="he">עִבְרִית (Ivrit)</option>
          <option value="sv">Svenska</option>
          <option value="no">Norsk</option>
          <option value="fi">Suomi</option>
          <option value="pl">Polski</option>
          <option value="th">ภาษาไทย (Phasa Thai)</option>
          <option value="vi">Tiếng Việt</option>
          <option value="cs">Čeština</option>
          <option value="hu">Magyar</option>
          <option value="ro">Română</option>
          <option value="da">Dansk</option>
          <option value="fa">فارسی (Fārsi)</option>
          <option value="id">Bahasa Indonesia</option>
          <option value="ms">Bahasa Melayu</option>
          <option value="uk">Українська (Ukrainska)</option>
          <option value="sr">Српски (Srpski)</option>
          <option value="hr">Hrvatski</option>
          <option value="bg">Български (Balgarski)</option>
          <option value="sk">Slovenčina</option>
          <option value="lt">Lietuvių</option>
          <option value="lv">Latviešu</option>
          <option value="et">Eesti</option>
          <option value="sl">Slovenščina</option>
          <option value="tl">Filipino</option>
          <option value="sw">Kiswahili</option>
          <option value="af">Afrikaans</option>
          <option value="is">Íslenska</option>
          <option value="ga">Gaeilge</option>
          <option value="cy">Cymraeg</option>
          <option value="eu">Euskara</option>
          <option value="gl">Galego</option>
          <option value="ca">Català</option>
          <option value="mt">Malti</option>
          <option value="lb">Lëtzebuergesch</option>
          <option value="mk">Македонски (Makedonski)</option>
          <option value="my">Myanmabhasa</option>
          <option value="ne">नेपाली</option>
          <option value="si">සිංහල</option>
          <option value="ml">മലയാളം</option>
          <option value="pa">ਪੰਜਾਬੀ</option>
          <option value="mr">मराठी</option>
          <option value="gu">ગુજરાતી</option>
          <option value="kn">ಕನ್ನಡ</option>
          <option value="te">తెలుగు</option>
          <option value="ta">தமிழ்</option>
          <option value="uz">Oʻzbekcha</option>
          <option value="kk">Қазақ тілі</option>
          <option value="tk">Türkmençe</option>
          <option value="ky">Кыргызча</option>
          <option value="hy">Тоҷикӣ</option>
          <option value="ka">Հայերեն</option>
          <option value="az">ქართული</option>
          <option value="az">Azərbaycan dili</option>
          <option value="sq">Shqip</option>
          <option value="bs">Bosanski</option>
          <option value="cnr">Crnogorski</option>
          <option value="ht">Kreyòl ayisyen</option>
          <option value="jv">ꦧꦱꦗꦮ</option>
          <option value="su">Basa Sunda</option>
          <option value="fj">Na Vosa Vakaviti</option>
          <option value="sm">Gagana fa'a Sāmoa</option>
          <option value="to">Lea Faka-Tonga</option>
          <option value="mi">Te Reo Māori</option>
          <option value="hmn">Hmoob</option>
          <option value="km">Khmer</option>
          <option value="lo">Lao</option>
          <option value="am">Amarəñña</option>
          <option value="so">Soomaali</option>
          <option value="ti">Tigrinya</option>
          <option value="om">Afaan Oromoo</option>
          <option value="zu">isiZulu</option>
          <option value="xh">isiXhosa</option>
          <option value="yo">Yorùbá</option>
          <option value="ig">Asụsụ Igbo</option>
          <option value="sn">chiShona</option>
          <option value="ny">Chichewa</option>
          <option value="tn">Setswana</option>
          <option value="st">Sesotho</option>
          <option value="rw">Ikinyarwanda</option>
          <option value="ku">Kurmancî</option>
          <option value="ps">Paṣhto</option>
          <option value="prs">Darī</option>
          <option value="ug">Uyghurche</option>
          <option value="bo">Bod skad</option>
          <option value="ce">Нохчийн мотт</option>
          <option value="ba">Башҡорт теле</option>
          <option value="mn">Монгол</option>
          <option value="tt">Татар теле</option>
          <option value="udm">Удмурт кыл</option>
          <option value="chm">Марий йылме</option>
          <option value="sah">Саха тыла</option>
          <option value="kom">Коми кыв</option>
          <option value="khn">Хӑнты ясанг</option>
          <option value="yrk">Ненэцяʼ вада</option>
          <option value="ch">Chamorro</option>
          <option value="mh">Kajin Majel</option>
          <option value="pau">A tekoi er a Belau</option>
          <option value="tpi">Tok Pisin</option>
          <option value="bi">Bislama</option>
          <option value="tet">Tetun</option>
          <option value="tvl">Te Gana Tuvalu</option>
          <option value="Faka'uvea">Faka'uvea</option>
          <option value="ty">Reo Tahiti</option>
          <option value="gil">Taetae ni Kiribati</option>
          <option value="ha">Harshen Hausa</option>
          <option value="wo">Wolof</option>
          <option value="ln">Lingála</option>
        </select>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="chat_section">
            <div className="message_title">
              {/* {translatedLang == 'ar' ? 'عندك سؤال؟ اسأل معلمك' : 'Have a Question? Ask Your Teacher'} */}
              {translatedLang == "ar"
                ? "قائمة الأسئلة السريعة"
                : "Quick Questions List"}
            </div>
            <div className="question_container">
              {questions.map((item) => {
                return (
                  <div className="question_section">
                    {item.map((child) => {
                      return (
                        <h3
                          onClick={() =>
                            sendDefinedText(
                              translatedLang == "ar" ? child.ar : child.en
                            )
                          }
                          className="question_text"
                        >
                          {translatedLang == "ar" ? child.ar : child.en}
                        </h3>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <h1 className="or_text">{translatedLang == "ar" ? "او" : "Or"}</h1>
          </div>
          <div className="footer_message">
            <input
              className="text_input_message"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder={
                translatedLang == "ar"
                  ? "إرسل سؤلك للمعلم كتابتا أو صوتا "
                  : "Write your question ( text or voice )?"
              }
            />
            <button onClick={handleSendText} className="chat_button">
              <img className="chat_button_img" src="../send.png" alt="Send" />
            </button>
            <button className="chat_button" onClick={handleMicrophoneClick}>
              <img
                className={`chat_button_img ${isListening ? "red" : ""}`}
                src="../microphone.png"
                alt="Microphone"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Message;
