import React, { useEffect, useRef, useState } from "react";
import { getLastNWords, speakMessage, translate } from "./utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSocket } from "./Context/SocketContext";

const Lecture = () => {
  const [message, setMessage] = useState("");
  const [enableVoice, setEnableVoice] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [lectureCompleted, setLectureCompleted] = useState(false);

  const enableLangRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const socket = useSocket();

  const lang = sessionStorage.getItem("translatedLang") || "ar";
  const studentId = sessionStorage.getItem("studentId");
  const roomId = sessionStorage.getItem("roomId");

  useEffect(() => {
    if (studentId == null || studentId == "") {
      navigate("/student-login");
    }
  }, []);

  useEffect(() => {
    // Check if we should show the message
    if (location.state?.showMessage) {
      setShowMessage(true);

      // Hide the message after 3 seconds
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      // Cleanup the timer on unmount
      return () => clearTimeout(timer);
    }
  }, [location.state]);

  useEffect(() => {
    if (socket) {
      console.log("socket: ", socket);

      socket.on("/student/new_teacher_text", (data) => {
        let receivedData = JSON.parse(data);
        console.log(receivedData.message);
        translate(
          receivedData.message,
          receivedData.language.split("-")[0],
          lang
        ).then((res) => {
          setMessage(getLastNWords(res, 10));
          if (
            enableLangRef &&
            enableLangRef.current &&
            enableLangRef.current.value
          ) {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then(function (stream) {
                console.log("You let me use your mic!");
                speakMessage(res, lang);
              })
              .catch(function (err) {
                console.log("No mic for you!");
              });
          } else {
            console.log("voice disable");
          }
        });
      });

      socket.on("/student/lecture_completed", () => {
        console.log("lecture completed");
        setLectureCompleted(true);
        setTimeout(() => {
          handleBack();
        }, 5000);
      });
    }

    return () => {};
  }, [enableVoice, socket]);

  const handleBack = () => {
    console.log("back");
    const userAgent = navigator.userAgent;

    // OS Information (basic parsing)
    const osVersion = /Windows/.test(userAgent)
      ? "Windows"
      : /Mac/.test(userAgent)
      ? "MacOS"
      : /Android/.test(userAgent)
      ? "Android"
      : /iPhone|iPad|iPod/.test(userAgent)
      ? "iOS"
      : "Unknown";

    // Platform information from navigator
    const deviceModel = navigator.platform || "Unknown";

    socket.emit(
      "/student/user_disconnected",
      JSON.stringify({
        userId: studentId,
        roomId: sessionStorage.getItem("roomId"),
        platform: "web",
        deviceModel: deviceModel,
        osVersion: osVersion,
        appVersion: "1.0.0",
      })
    );

    navigate("/student");
  };

  return (
    <div className="lecture_wrapper">
      <div className="name_input_container">
        <div className="back_btn" onClick={handleBack}>
          <img className="icon" src="../back.png" alt="Back" />
        </div>
      </div>
      {showMessage && (
        <div className="className">
          <h1>Your message has been successfully sent to the teacher!!!</h1>
        </div>
      )}
      {lectureCompleted && (
        <div className="className">
          <h1>Lecture has been completed by teacher !!!</h1>
        </div>
      )}
      <div className="text-message">{message}</div>

      <Link className="message_button_container" to="/message">
        <img className="message_button" src="../chat.png" alt="" srcset="" />
      </Link>
      <div className="footer">
        <div></div>
      </div>
    </div>
  );
};

export default Lecture;
